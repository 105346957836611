import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@popmenu/common-ui';

import Loading from '../../../shared/Loading';
import ProfileNavigation from './ProfileNavigation';
import ProfilePageContentRegion from './ProfilePageContentRegion';
import { AH } from '../../shared/AccessibleHeading';
import { useCurrentUser } from '../../../shared/CurrentSessionProvider';
import { useProfileUser } from '../hooks';

const ProfileContent = () => {
  const currentUser = useCurrentUser();
  const profileUserQuery = useProfileUser();

  if (profileUserQuery.query.loading) {
    return <Loading />;
  }

  if (currentUser?.id) {
    return (
      <Box display="flex" flexGrow={1}>
        <ProfileNavigation />
        <ProfilePageContentRegion />
      </Box>
    );
  }

  return (
    <AH typography variant="h6">
      <FormattedMessage
        id="profile.profile_unavailable"
        defaultMessage="Profile is Unavailable"
      />
    </AH>
  );
};

export default ProfileContent;
